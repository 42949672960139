import React from 'react';
import './classes/SizeChartModal.css';

const SizeChartModal = ({ show, title, data, onClose }) => {
  if (!show) {
    return null;
  }

  return (
    <div className="size-chart-modal-overlay">
      <div className="size-chart-modal">
        <div className='size-hdg'>
        <button onClick={onClose} className="apparel-modal-close">×</button>
        <p className='sz-hdg'>Size Chart</p>
        </div>

        <p className='size-title'>{title}</p>
        <div dangerouslySetInnerHTML={{__html: data}} />
        
      </div>
    </div>
  );
};

export default SizeChartModal;
