import React, { useState, useRef, useEffect } from 'react';
import './classes/AdvancedCertificate.css';
import './classes/SingleAdvancedCertificate.css';
import { configData } from "../config";
import ReCAPTCHA from "react-google-recaptcha";
import MinicartLength from './MinicartLength';
import ReactDOM from "react-dom/client";
import { Link } from 'react-router-dom';
import Select from 'react-select';
import PhoneInput from 'react-phone-input-2'
import 'react-phone-input-2/lib/style.css'

const BaseballAdvancedCertificate = () => {
    const [isImageFullScreen, setIsImageFullScreen] = useState(false);
    
    const [type, setOpenModal] = useState(null);
    const [statelist, setStateList] = useState({});
    const [countrieslist, setCountriesList] = useState({});
    const [agentproducts, setAgentProducts] = useState({});
    const [allproducts, setAllProducts] = useState({});
    const [packageproducts, setPackageProducts] = useState([]);
    const [paymentplanlist, setPaymentPlanList] = useState([]);
    const [packageid, setPacakgeId] = useState(null);
    const [agentcourse, setAgentCourse] = useState(null);
    const [electivecourse, setElectiveCourse] = useState(null);
    const [paymentplan, setPaymentPlan] = useState(null);
    const [message, setSuccessMessage] = useState([]);
    const [disabled, setDisabled] = useState(false);
    const [buttontext, setButtonText] = useState("SUBMIT");
    const [formData, setFormData] = useState({
        first_name: '',
        last_name: '',
        email: '',
        primary_phone: '',
        city: '',
        country: '',
        state: '',
        province: '',
        question: '',
        pageURL: window.location.href, 
        showStateField: true,
        showProvinceField: false,
        showForm: true,
        showSuccessMessage: false,
    });

    const app_url = configData.APP_URL;
    const domain_url = configData.DOMAIN_URL;
    const drupal_domain_url = configData.DRUPAL_DOMAIN_URL;
    const recaptchaRef = useRef(null);

    useEffect(() => {
        retrieveAgentProducts();
        retrieveAllProducts();
        retrieveStateList();
        retrieveCountriesList();
    }, [])

    async function retrieveAgentProducts() {
        const agentproductlistresponse = await fetch(
          app_url+'smwwcommercepackage/getagentproducts',
          {
            method: "GET",
      
            headers: {
              'Content-Type': 'application/json',
            },
          }
        )
        const agentproductlistdata = await agentproductlistresponse.json();
        const agentproductlist = agentproductlistdata.data.result;
        setAgentProducts(agentproductlist);
        
    }
    async function retrieveAllProducts() {
        const productlistresponse = await fetch(
          app_url+'smwwcommercepackage/getproductlist',
          {
            method: "GET",
      
            headers: {
              'Content-Type': 'application/json',
            },
          }
        )
        const productlistdata = await productlistresponse.json();
        const productlist = productlistdata.data.result;
        setAllProducts(productlist);
        
    }
    async function retrievePackageProducts(id) {
        const productlistresponse = await fetch(
          app_url+'smwwcommercepackage/getpackageproductlist?id='+id,
          {
            method: "GET",
      
            headers: {
              'Content-Type': 'application/json',
            },
          }
        )
        const productlistdata = await productlistresponse.json();
        const productlist = productlistdata.data.result;
        setPackageProducts(productlist);
        
    }
    async function retrievePaymentPlanList(postData) {
  
        const paymentplanlistresponse = await fetch(
        app_url+'smwwcommercepackage/getpaymentplanlist',
        {
            method: 'POST',
            body: JSON.stringify(postData),
            headers: {
            'Content-Type': 'application/json',
            },
        });
        const paymentplanlistdata = await paymentplanlistresponse.json();
        const payplanlist = paymentplanlistdata.data.data;
        setPaymentPlanList(payplanlist);
       
    }
    async function retrieveStateList() {
        const statelistresponse = await fetch(
          app_url+'smwwstates/getstatelist',
          {
            method: "GET",
    
            headers: {
              'Content-Type': 'application/json',
            },
          }
        )
        const statelistdata = await statelistresponse.json();
        
        const statelist = {};
        Object.keys(statelistdata.data.result).forEach(function(key) {
          
          statelist[key] = statelistdata.data.result[key];
        
        });
        setStateList(statelist);
       
    }
    async function retrieveCountriesList() {
        const countrieslistresponse = await fetch(
          app_url+'smwwcountries/getcountrieslist',
          {
            method: "GET",
    
            headers: {
              'Content-Type': 'application/json',
            },
          }
        )
        const countrieslistdata = await countrieslistresponse.json();
    
        setCountriesList(countrieslistdata.data.result);
        console.log(countrieslistdata.data.result);
        
    }
    const handleChange = event => {
        const { name, value} = event.target;
        setFormData(prevState => ({
          ...prevState,
          [name]: value,
        }));
      
        if(event.target.name === 'country') {
            
          if(event.target.value === 'US') {
            setFormData(prevState => ({
              ...prevState,
              showProvinceField: false,
              showStateField: true,
            }));
    
          } else {
            setFormData(prevState => ({
              ...prevState,
              showProvinceField: true,
              showStateField: false,
            }));
           
          }
        }
    }
    
    const handleImageClick = () => {
        setIsImageFullScreen(true);
    };

    const handleCloseClick = () => {
        setIsImageFullScreen(false);
    };

   
    const sitekey = configData.GOOGLE_RECAPTCHA_SITE_KEY;

    const handleModalOpen = (type,id) => {
        setPacakgeId(id);
        setElectiveCourse(null);
        setAgentCourse(null);
        retrievePackageProducts(id);
        if(type === "payment_plan") {
            const postData = {
                id: id
            }
            retrievePaymentPlanList(postData);
        }
        setOpenModal(type);
        
    };

    const handleModalClose = () => {
        setOpenModal(null);
    };
   
    const handleAgentCourseSubmit = async event => {
        event.preventDefault();

        if(agentcourse === null) {
            alert("Pick any Agent Course");
            return false;
        } else {
            
            setOpenModal("elective_course");
        }
        
    }
   
    const handleElectiveCourseSubmit = async event => {
        event.preventDefault();
        if(electivecourse === null) {
            alert("Pick any Elective Course");
            return false;
        } else {
            const postData = {
                id: packageid,
                agent_course: agentcourse,
                elective_course: electivecourse,
            }
            retrievePaymentPlanList(postData);
            setOpenModal("payment_plan");
        }
    }
    
    const handleSubmit = async event => {
        event.preventDefault();
        const postData = {
            id: packageid,
            agent_course: agentcourse,
            elective_course: electivecourse,
            payment_plan: paymentplan,
            order_id: localStorage.getItem('order_id'),
            country: '',
        }
        const response = await fetch(app_url+'smwwcommercepackage/addtocart', {
            method: 'POST',
            body: JSON.stringify(postData),
            headers: {
                'Content-Type': 'application/json',
            },
        });
        const data = await response.json();
        const yii_session = data.data.session;
        if(data.data.result === "order_created") {
            if(yii_session.order_id) localStorage.setItem('order_id', yii_session.order_id);
            else localStorage.setItem('order_id', data.data.order_id);
            setOpenModal('success');
            setSuccessMessage(data.data.messages.success);
            
            const root = ReactDOM.createRoot(document.getElementById("top-header-cart-icon"));
            root.render(<MinicartLength length={data.data.products.length} />);
        }
    }
    const validateForm = () => {
        console.log(formData);
        if(formData.first_name === "") {
           alert("First Name is required");
           return false;
        }
        if(formData.last_name === "") {
            alert("Last Name is required");
            return false;
        }
        if (!formData.email.trim()) {
            alert("Email is required");
            return false;
        } else if (!/\S+@\S+\.\S+/.test(formData.email)) {
            alert("Email is invalid");
            return false;
        }
        if (!formData.primary_phone.trim()) {
            alert("Phone Number is required");
            return false;
            
        }
        if (!formData.country.trim()) {
            alert("Country is required");
            return false;
            
        }
        const recaptchaValue = recaptchaRef.current.getValue();
          if (!recaptchaValue) {
            alert("Please verify the reCAPTCHA!");
            return false;
          }
         return true;
         
    }
    const handleFormSubmit = async event => {
        event.preventDefault();
    
        if (validateForm()) {
          setDisabled(true);
          setButtonText("Saving please wait...");
          const postData = formData; 
          const response = await fetch(app_url+'smwwcommercepackage/application', {
          method: 'POST',
          body: JSON.stringify(postData),
          headers: {
            'Content-Type': 'application/json',
          },
          });
          const data = await response.json();
          if(data.data.result === "user_created") {
            setFormData(prevState => ({
                ...prevState,
                showForm: false,
                showSuccessMessage: true,
              }));
            
          }
        }
    };
    const renderModal = (type,id) => {
        if (type === "agent") {
            return (
                <div className="ad-modal-overlay">
                    <div className="ad-modal">
                        <button className="adm-close-button" onClick={handleModalClose}>×</button>
                        <img 
                            src={domain_url+'/images/certs/'+id+'.png'} 
                            alt={`${type} badge`} 
                            className="modal-badge" 
                        />
                        <h2>Pick an Agent Course</h2>
                        
                        <form className='ad-modal-form'>
                        <div class="modal-body"><ul className="product-list">
                            {agentproducts.map((item, index) => (
                                <>
                                    
                                    <li><input type="radio" id="f-option" name="only_agent_courses" value={item.sku}  onChange={() => setAgentCourse(item.sku)}/>
                                    <label>{item.name}
                                    </label><div class="check"></div></li>
                                </>
                             ))}</ul>
                        </div>
                            <button className='modal-continue-button' type="submit" onClick={handleAgentCourseSubmit}>Continue</button>
                        </form>
                        
                    </div>
                </div>
            );
        }
        if (type === "payment_plan") {
            return (
                <div className="ad-modal-overlay">
                    <div className="ad-modal">
                        <button className="adm-close-button" onClick={handleModalClose}>×</button>
                        <img 
                            src={domain_url+'/images/certs/'+id+'.png'} 
                            alt={`${type} badge`} 
                            className="modal-badge" 
                        />
                        <h2>Pick a Payment Plan</h2>
                        
                        <form className='ad-modal-form'><ul className="product-list">
                            {paymentplanlist.map((item, index) => (
                                <>
                                    <li>
                                    <input type="radio" id="f-option" name="payment_plans" value={index+1} onChange={() => setPaymentPlan(index+1)}/>
                                    <label>{item.name} {item.help_text}<span class="sta-pay">${item.price}</span>
                                    </label><div class="check"></div></li>
                                </>
                            ))}
                          </ul>
                            <button className='modal-continue-button' type="submit" onClick={handleSubmit}>Continue</button>
                       
                        </form>
                    </div>
                </div>
            );
        }
        if (type === "elective_course") {
           
            return (
                <div className="ad-modal-overlay">
                    <div className="ad-modal">
                        <button className="adm-close-button" onClick={handleModalClose}>×</button>
                        <img 
                            src={domain_url+'/images/certs/'+id+'.png'} 
                            alt={`${type} badge`} 
                            className="modal-badge" 
                        />
                        <h2>Pick an Elective Course</h2>
                        
                        <form id="elective-course-form" className='ad-modal-form'>
                        <div class="modal-body"><ul className="product-list">
                            {allproducts.map((item, index) => (
                                
                                <>
                                    {!packageproducts.includes(item.sku)  && (
                                        
                                    <li><input type="radio" id="f-option" name="only_elective_courses" value={item.sku} onChange={() => setElectiveCourse(item.sku)}/>
                                    <label>{item.name}
                                    </label><div class="check"></div></li>
                                    )}
                                </>
                             ))}</ul>
                        </div>
                            <button className='modal-continue-button' type="submit" onClick={handleElectiveCourseSubmit}>Continue</button>
                       
                        </form>
                    </div>
                </div>
            );
            
        }
        if (type === "success") {
            return (
                <div className='ad-modal-overlay'>
                <div className="adv-success-message">
                    <div className='add-sucs'>
                    <p>Added Successfully</p>
                    <button className="ad-sucs-close-button" onClick={handleModalClose}>×</button>
                    </div>
                    <div className="adv-message-content">
                        <div className="adv-message-icon">
                            <i className="fa fa-check-circle"></i>
                        </div>
                        <div className="adv-message-text">
                            {message.map((message, index) => (
                                <p>{message}</p>
                            ))}
             
                        </div>
                    </div>
                    <div className="adv-success-buttons">
                        <Link to="/smww/cart">
                            <button className="adv-view-cart-button">View Cart</button>
                        </Link> 
                        <Link to="/smww/checkout">
                            <button className="adv-checkout-button">Checkout</button>
                        </Link>   
                        <button className="adv-close-button" onClick={handleModalClose}>Close</button>
                    </div>
                </div>
                </div>
            );

        }
        return null;
    };

    return (
        <div className="adv-cert-container single-adv-cert-container">
            <div className="full-width-header">
                <h2>BASEBALL FRONT OFFICE ADVANCED CERTIFICATE</h2>
            </div>
            <div class="certified_content">
                <h2><strong>Become a Baseball Front Office Expert!</strong></h2>
                <p>The SMWW Baseball Front Office Advanced Certificate program is designed to accelerate the careers of baseball professionals in management and operations. This program equips Agents &amp; Scouts&nbsp;with the advanced skills necessary to thrive in the competitive landscape of both major and minor baseball leagues worldwide, including MLB, MiLB, Nippon Professional Baseball (NPB), KBO, Liga Mexicana, and many others.</p>
                <p>This globally recognized program covers:</p>
                <ul>
                    <li>Baseball Technology &amp; Analytics: Master tools like TrackMan, Rapsodo, Baseball Savant, and Statcast to make informed decisions within a baseball organization.</li>
                    <li>Player &amp; Team Development: Deepen your expertise in advanced scouting, player development, and team dynamics.</li>
                    <li>Advanced Baseball Management: Learn critical techniques for handling roles as agents or scouts, navigating player situations, and managing salary cap and roster challenges.</li>
                </ul>
            </div>
            <div className="single-adv-flex-container">
            <div className="single-adv-flex-item-left">
                
                <div className="single-adv-cert-header">
                    <h1>Advanced Certificate Benefits</h1>
                </div>
                <div className="single-adv-cert-content ">
                    <div className="single-adv-cert-text">
                    <p>The Baseball Front Office Advanced Certificate Courses are carefully curated by our team of baseball experts to equip you with the skills needed to succeed both on and off the field in the club front office.</p>
                    <p>This program includes:</p>
                    <ul><li>A LinkedIn Certificate Badge to showcase your Baseball Front Office skills and accomplishments, establishing credibility with potential employers and collaborators.</li>
                        <li>A personalized wooden plaque commemorating your achievement.</li>
                        <li>A Resume Redesign, featuring your course mentors to enhance your professional profile.</li>
                        <li>Access to the SMWW Network, connecting you with industry professionals.</li>
                        <li>A baseball business portfolio to last a lifetime, showcasing your expertise and achievements.</li>
                        <li>A competitive edge in the baseball management landscape.</li>
                    </ul>
                    </div>
                    <a href={drupal_domain_url+'/advanced_certificates/faq'} ><button className="faq-button">FAQ</button></a>
                    </div>
               
            </div>
            <div className="single-adv-flex-item-right">
                <div className="single-adv-cert-header">
                    <h1>Included Courses</h1>
                </div>
                <div className="single-adv-cert-content ">
                    <div className="single_adv_image_content">
                        <img 
                            src={domain_url+'/images/certs/baseball-frontoffice-background-1.jpg'} 
                            
                            className="single-adv-cert-background" 
                        />
                        <img 
                            src={domain_url+'/images/certs/baseball-frontoffice-badge.png'} 
                            
                            className="single-adv-cert-logo" 
                        />
                    </div>
                    <div class="package-content">
                        <a href={domain_url+'/courses/baseball-agent-course'}>Baseball Agent</a>
                        <br/>
                        <a href={domain_url+'/courses/baseball-analytics'}>Baseball Analytics</a>
                        <br/><a href={domain_url+'/courses/baseball-gm-scouting'}>Baseball GM & Scouting</a>
                        <br/><a href={domain_url+'/courses/baseball-player-development'}>Baseball Player Development</a><br/>
                        <a href={domain_url+'/courses'}>and 1 Course of Your Choice</a><br/>
                        <button className="enroll_btn" onClick={() => handleModalOpen('elective_course',255)}>ENROLL TODAY</button>
                    </div>
                </div>
            </div>
            
            </div>
            <></>
            {renderModal(type,packageid)}
            {/* Second Container */}
           

            <div className='low-rate'>
                <div className="full-width-header">
                    <h2>Save and start your career with Advanced Certificates!</h2>
                </div>
                <p><span className='first_rate'> $9,150 </span> &nbsp; <span className='new-rate'> $6,795</span></p>
            </div>

            <br /> <br />

            {/* Third Container */}
            <div className="linkedin-container">
                <div className="linkedin-info">
                    <h2>Stand Out on LinkedIn</h2>
                    <div className='linkedin-badge-img'>
                        <img 
                        src={domain_url+'/images/certs/baseball-frontoffice-badge.png'}
                        alt="athlete-badge" 
                        />
                    </div>
                    <p>When you earn an Advanced <br />
                        Certificate, you can show it off on your <br /> 
                        LinkedIn profile.</p>
                   <></>
                    <p>Instructions on how to add <br />
                        it are provided with your <br />
                        certificate.</p>
                </div>
                <div className="linkedin-form">
                {formData.showForm &&
                    <div className = "form-wrapper">
                    <h2>Any Questions?</h2>
                    <p>Fill out the form below:</p>
                    <form>
                        <input type="text" placeholder="First name" name="first_name" value={formData.first_name} onChange={handleChange} required/>
                        <input type="text" placeholder="Last name" name="last_name" value={formData.last_name} onChange={handleChange} required/>
                        <input type="email" placeholder="Email" name="email" value={formData.email} onChange={handleChange} required/>
                        <PhoneInput
                            className="number"
                            name="primary_phone"
                            country={"us"}
                            value={formData.primary_phone}
                            onChange={(phone) =>
                            handleChange({ target: { value: phone, name: 'primary_phone' } })
                            }
                        />
                        
                        <Select id="country" name="country" className='select-country' 
                        onChange={(val) => handleChange({ target: { value: val.value, name: 'country' } })} options={countrieslist}
                        placeholder='Select Country'
                        >
                        </Select>
                        {formData.showStateField &&
                            <select  id="state" name="state" value={formData.state} onChange={handleChange}>
                                <option value="">Select State</option>
                                {Object.keys(statelist).map(state => {
                                    return <option key={statelist[state].name} value={statelist[state].name}>{statelist[state].name}</option>
                                })}
                            </select>
                        }
                        {formData.showProvinceField &&
                           
                            <input type="text" id="province" name="province" value={formData.province} onChange={handleChange} placeholder="Province"/>
          
                        }
                        <textarea placeholder="Question" name="question" value={formData.question} onChange={handleChange} required></textarea>
                        <div className="recaptcha">
                            <ReCAPTCHA
                                ref={recaptchaRef}
                                sitekey={sitekey}
                                size="visible"
                                required
                            />
                        </div>
                        <button disabled={disabled} type="submit" onClick={handleFormSubmit}>{buttontext}</button>
                    </form>
                    </div>
                }
                {formData.showSuccessMessage &&
                    <div className="success_message_wrapper">
                        Thank you for your interest!<br/> Someone from SMWW will be contacting you soon.
                    </div>
                }
                </div>
            </div>
        </div>
    );
};

export default BaseballAdvancedCertificate;
