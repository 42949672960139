import { useState, useEffect, useRef} from 'react';
import './classes/ReferralPage.css';
import { configData } from "../config";


const CertificatePage = (uid) => {
    <div className="certificate-page">

    </div>
};

export default CertificatePage;