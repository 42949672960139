import React from 'react'
import { useState, useEffect} from 'react';
import { useParams } from 'react-router-dom';
import { Link, useNavigate,useLocation  } from 'react-router-dom';
import './classes/UserTab.css';
import { configData } from "../config";
import EditProfile from './EditProfile';
import OrderPage from './OrderPage';
import ReferralPage from './ReferralPage';
import CertificatePage from './CertificatePage';
import Loading from './Loading';


function ViewTab  ()  {
 
  const [toggle, setToggle] = useState(1);

  const[userDetails,setUserDetails] = useState({});
  const[userOrderDetails,setUserOrderDetails] = useState({});
  const[userSlug,setUserSlug] = useState({});
  const[drupalUID,setDrupalUID] = useState({});
  const[certificateCount,setCertificateCount] = useState({});
  const [isLoading, setIsLoading] = useState(true);
  const[totalamount,setTotalAmount] = useState(0.0);
  const location  = useLocation();
  const drupal_uid = localStorage.getItem('drupal_uid');
  var {uid} = useParams();
  var {username} = useParams();
  if(location.state) {
    uid = location.state;
  }
 
  const {tab} = useParams();
  
  const loggedin_uid = localStorage.getItem('uid');
  const navigate = useNavigate();
  
  const app_url = configData.APP_URL;

  const domain_url = configData.DOMAIN_URL;
 
  const drupal_domain_url = configData.DRUPAL_DOMAIN_URL;
  
  function updateToggle(id){
    setToggle(id)
    if(id == 1) navigate("/users/"+userSlug,{state:uid});
    if(id == 2) navigate("/user/"+uid+"/edit");
    if(id == 3) navigate("/user/"+uid+"/my_orders");
    if(id == 4) navigate("/user/"+uid+"/myreferral");
    if(id == 5) window.location.href = drupal_domain_url+"/user/"+localStorage.getItem('drupal_uid')+"/certificate";

  }
  useEffect(() => {
   
    retrieveUserDetails();
    retrieveUserOrderDetails();
    retrieveUserDetailsByUsername();
    retrieveUserCertificateDetails();
    if(tab == "my_orders") {
      setToggle(3);

    }
    if(tab == "edit") {
      setToggle(2);

    }
    if(tab == "myreferral") {
      setToggle(4);

    }
    if(tab == "certificate") {
       window.location.href = drupal_domain_url+"/user/"+localStorage.getItem('drupal_uid')+"/certificate";

    }
    setTimeout(() => {
      setIsLoading(false);
    }, 800);
    
  },[]);


  async function retrieveUserDetails() {
    
    if(uid && !Number.isNaN(uid)) {
    const response = await fetch(
      app_url+'viewprofile/getuserdetails?uid='+uid,
      {
        method: "GET",
  
        headers: {
          'Content-Type': 'application/json',
        },
      }
    )
    const responsedata = await response.json();
    if(responsedata.data.result === "Success") {
      if(responsedata.data.date_of_app) responsedata.data.user.date_of_app = responsedata.data.date_of_app;
      setUserDetails(responsedata.data.user);
      setTotalAmount(responsedata.data.total_amount_paid);
      setDrupalUID(responsedata.data.user.drupal_uid);
      if(location.pathname.indexOf("user/") > -1) {
        navigate("/users/"+responsedata.data.user_slug,{state:responsedata.data.user.drupal_uid});
      }
      setUserSlug(responsedata.data.user_slug);
    }
  }
  }
  async function retrieveUserDetailsByUsername() {
    
    if(username) {
    const response = await fetch(
      app_url+'viewprofile/getuserdetailsbyusername?username='+username,
      {
        method: "GET",
  
        headers: {
          'Content-Type': 'application/json',
        },
      }
    )
    const responsedata = await response.json();
    if(responsedata.data.result === "Success") {
      if(responsedata.data.date_of_app) responsedata.data.user.date_of_app = responsedata.data.date_of_app;
      setUserDetails(responsedata.data.user);
      setTotalAmount(responsedata.data.total_amount_paid);
      setDrupalUID(responsedata.data.user.drupal_uid);
     
      if(location.pathname.indexOf("user") > -1) {
        navigate("/users/"+responsedata.data.user_slug,{state:responsedata.data.user.drupal_uid});
      }
      setUserSlug(responsedata.data.user_slug);
    }
  }
  }
  async function retrieveUserCertificateDetails() {
    
    if(uid && !Number.isNaN(uid)) {
    const response = await fetch(
      app_url+'certificate/getcertificatelist?uid='+uid,
      {
        method: "GET",
  
        headers: {
          'Content-Type': 'application/json',
        },
      }
    )
    const responsedata = await response.json();
    if(responsedata.data.result === "Success") {
      setCertificateCount(responsedata.data.count);
    }
  }
  }
  async function retrieveUserOrderDetails() {
    if(uid) {
    const response = await fetch(
      app_url+'myorder/getuserorderdetails?uid='+uid,
      {
        method: "GET",
  
        headers: {
          'Content-Type': 'application/json',
        },
      }
    )
    const responsedata = await response.json();
    if(responsedata.data) {
    setUserOrderDetails(responsedata.data);
    } 
  }
  }
  return (
   <>
   {isLoading ? (
        <Loading />
      ) : (
    <div id="main">
 
      <div class="left"></div>

      <div class="center left-center">
      {loggedin_uid && !Number.isNaN(loggedin_uid) && (
      <><h2 class="element-invisible">Primary tabs</h2>
      <ul class="tabs primarya">
        <li className= {toggle === 1? "active" : ""} onClick={()=> updateToggle(1)}><a>View<span class="element-invisible">(active tab)</span></a></li>
        <li className= {toggle === 2? "active" : ""} onClick={()=> updateToggle(2)}><a>Edit Profile</a></li>
        <li className= {toggle === 3? "active" : ""} onClick={()=> updateToggle(3)}><a>My Orders</a></li>
        <li className= {toggle === 4? "active" : ""} class="four" onClick={()=> updateToggle(4)}><a>My Referral Wallet</a></li>
        {certificateCount > 0 && (
        <li className= {toggle === 5? "active" : ""} onClick={()=> updateToggle(5)}><a>My Credentials</a></li>
        )}
      </ul></>    
      )}
      <div  className= {toggle === 1? "show-content" : "content"}>
        <div class="profile" typeof="sioc:UserAccount" >
          <div class="user-image" style={{ marginRight: '2%' }}>
          {userDetails.picture ? (  
            <><img  src={userDetails.picture} width="100px" height="100px" alt = "user"/></>
          ):(
            <><img  src={domain_url+"pictures/notavailable.jpg"} width="100px" height="100px" alt = "user"/></>
          )}
           </div>
          <div class="user-name-loc">
            <span class="user-name" style={{marginBottom:"5px"}}>{userDetails.first_name} {userDetails.last_name}</span>
            
            <span class="user-loc" style={{marginBottom:"5px"}}>{userDetails.city && userDetails.city+', '} 
              {userDetails.state && userDetails.state+', '} {userDetails.province && userDetails.province+', '} {userDetails.country && userDetails.country}</span>
            <span style={{ backgroundColor: '#FFFF00', padding: '8px', color: '#000' }}><strong>Recent app: {userDetails.date_of_app}</strong></span>
          </div>
          <div class="user_total_amount" style={{ float: 'right', fontWeight: 'bold', fontSize: '16px' }}>Total Purchased: ${totalamount}</div> 
        </div>
      </div>
      <div  className= {toggle === 2? "show-content" : "content"}>
        <EditProfile uid={uid} />
      </div>
      <div  className= {toggle === 3? "show-content" : "content"}>
        <OrderPage data={userOrderDetails}/>
      </div>
      <div  className= {toggle === 4? "show-content" : "content"}>
        <ReferralPage uid={uid}/>
      </div>
      <div  className= {toggle === 5? "show-content" : "content"}>
        <CertificatePage uid={uid}/>
      </div>
    </div>

    


<div class="right">

<div className="order-details-page-quick-links">
          <h3>Quick Links</h3>
          <ul>
            <li className='header-hello-text'><a className="quick_link_anchor" onClick={()=> updateToggle(2)}>Manage My Profile</a></li>
            <li>
              <a href="/smww_pay">
              <input type="button" className='quick-links-reg-button' value="Register for a Course"/>
              </a>
            </li>
           
            {localStorage.getItem('masquerade') && (
              localStorage.getItem('masquerade') == 1 && (
                <li>
                  <a className="quick_link_anchor" href={'/miscellaneous/charge/'+drupal_uid} >
                   Miscellaneous Charge
                  </a>
                </li>
               ))}
            </ul>
        </div>

</div>
  <div class="clear"></div> 
  
</div>
)}
    </>
    
    
  )
}

export default ViewTab