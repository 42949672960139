import React, { useState, useEffect, useLayoutEffect} from 'react';
import { configData } from "../config";
import './classes/CoursePay.css';
import { Link,useLocation,useNavigate } from 'react-router-dom';
import MinicartLength from './MinicartLength';
import ReactDOM from "react-dom/client";
import CartTooltip from './CartTooltip';
import Loading from './Loading';

function CoursePay() {
  const [selectedOptions, setSelectedOptions] = useState({});
  const [country, setSelectedCountry] = useState({});
  const [countrieslist, setCountriesList] = useState({});
  const [classroomlist, setClassroomList] = useState([]);
  const [classroom, setClassroom] = useState([]);
  const [productlist, setProductList] = useState([]);
  const [discountlist, setDiscountList] = useState([]);
  const [showSuccessMessage, setShowSuccessMessage] = useState(false);
 
  const [applicationmessage, setApplicationMessage] = useState('');
  const [message, setSuccessMessage] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const navigate = useNavigate();
  const app_url = configData.APP_URL;
  const location  = useLocation();
  
  const msg = location.state;
 
  const handlePaymentChange = (courseSku, paymentOption) => {
    
    document.getElementById("course-pay").reset();
    setSelectedOptions({
      
      [courseSku]: paymentOption,
    });
   
    if(classroom[courseSku]) {
       
    } else {
      setClassroom({});
    }
    console.log(classroom);
  };
  const handleClassroomChange = (courseSku, sel_classroom) => {
    
    
    setClassroom({[courseSku]: sel_classroom});
    
   };
  useEffect(() => {
    
    retrieveCountriesList();
    retrieveClassroomLists();
    retrieveCountryDiscounts();
   
    const country = localStorage.getItem('sel_country');
   
    const  country_discount = discountlist[country];
    
    if(country_discount > 0) {
      retrieveProductsforDiscount(country, country_discount);
    } else {
      retrieveProducts();
    }
    setSelectedCountry(country);
    if(msg) {
      setApplicationMessage(msg);
      window.history.replaceState({}, '');
      
    }
    setTimeout(() => {
      setIsLoading(false);
    
    }, 1000);

    setTimeout(() => {
      setIsLoading(false);
      const hash = location.hash;
    if (hash) {
      
    const element = document.querySelector(hash);
    if (element) {
      
      element.scrollIntoView({behavior: "smooth"})
    }
  }
    }, 1200);
    
 }, [discountlist[country]])
 
 async function retrieveCountriesList() {
  const countrieslistresponse = await fetch(
    app_url+'smwwcommercecountries/getcountrieslist',
    {
      method: "GET",

      headers: {
        'Content-Type': 'application/json',
      },
    }
  )
  const countrieslistdata = await countrieslistresponse.json();
  
  const countrieslist = {};
  Object.keys(countrieslistdata.data.result).forEach(function(key) {
    
    countrieslist[key] = countrieslistdata.data.result[key];
  
  });
 
  setCountriesList(countrieslist);
  
}
async function retrieveProducts() {
  const productlistresponse = await fetch(
    app_url+'smwwcommerceproduct/getproductlist',
    {
      method: "GET",

      headers: {
        'Content-Type': 'application/json',
      },
    }
  )
  const productlistdata = await productlistresponse.json();
  const productlist = productlistdata.data.result;
  setProductList(productlist);
  
}
async function retrieveProductsforDiscount(country,discount) {
  
  const productlistresponse = await fetch(
    app_url+'smwwcommerceproduct/getproductsdiscountedlist?country='+country+'&discount='+discount,
    {
      method: "GET",

      headers: {
        'Content-Type': 'application/json',
      },
    }
  )
  const productlistdata = await productlistresponse.json();
  const productlist = productlistdata.data.result;
  
  setProductList(productlist);
  
}
async function retrieveCountryDiscounts() {
  const discountlistresponse = await fetch(
    app_url+'smwwcommercediscount/getdiscountlist',
    {
      method: "GET",

      headers: {
        'Content-Type': 'application/json',
      },
    }
  )
  const discountlistdata = await discountlistresponse.json();
  const discountlist = discountlistdata.data.result;
  
  setDiscountList(discountlist);
  
}
async function retrieveClassroomLists() {
  const classroomlistresponse = await fetch(
    app_url+'smwwcommercepay/getclassroomlists',
    {
      method: "GET",

      headers: {
        'Content-Type': 'application/json',
      },
    }
  )
  const classroomlistdata = await classroomlistresponse.json();
  setClassroomList(classroomlistdata.data.result);
  
  
}
const validateForm = (name) => {
  let isValid = true;
  if(classroomlist[name]) {
    if(!classroom[name]) {
      document.getElementById('select'+name).className += " show_error";
      alert("Please select the classroom start date for "+classroomlist[name]['course_name']);
      return false;
    }
  }
  
  return isValid;
};
const handleAddtoCart = async event => { 
  event.preventDefault();
  
  if(!selectedOptions[event.target.name]) {
   const selectedOption = {[event.target.name]: 1};
   const isValid = validateForm(event.target.name);
   if (isValid) {
    setSelectedOptions({
      
      [event.target.name]: 1,
    });
  
   const postData = {
    lineitems: selectedOption,
    country: country,
    courses_classroom:classroom,
    order_id: localStorage.getItem('order_id'),
    uid:localStorage.getItem('uid')
    }
    const response = await fetch(app_url+'smwwcommercepay/addtocart', {
      method: 'POST',
      body: JSON.stringify(postData),
      headers: {
        'Content-Type': 'application/json',
      },
      });
      const data = await response.json();
      const yii_session = data.data.session;
      if(data.data.result === "order_created") {
        document.getElementById("course-pay").reset();
        if(yii_session.order_id) localStorage.setItem('order_id', yii_session.order_id);
        else localStorage.setItem('order_id', data.data.order_id);
        setSuccessMessage(data.data.messages.success);
        setShowSuccessMessage(true);
        const root = ReactDOM.createRoot(document.getElementById("top-header-cart-icon"));
        root.render(<MinicartLength length={data.data.products.length} />);
       
      }
    }
 } else {
  const isValid = validateForm(event.target.name);
   if (isValid) {
  const postData = {
    lineitems: selectedOptions,
    country: country,
    courses_classroom:classroom,
    order_id: localStorage.getItem('order_id'),
    uid:localStorage.getItem('drupal_uid')
    }
         

const response = await fetch(app_url+'smwwcommercepay/addtocart', {
method: 'POST',
body: JSON.stringify(postData),
headers: {
'Content-Type': 'application/json',
},
});
const data = await response.json();
const yii_session = data.data.session;
if(data.data.result === "order_created") {
  
  localStorage.setItem('order_id', data.data.order_id);
  localStorage.setItem('drupal_orderid', data.data.drupal_orderid);
  setSuccessMessage(data.data.messages.success);
  setShowSuccessMessage(true);
  const root = ReactDOM.createRoot(document.getElementById("top-header-cart-icon"));
  root.render(<MinicartLength length={data.data.products.length} />);

}
   }
 }
 
  
  
}
const handleChange = event => {
  const { name, value} = event.target;
 
  if(event.target.name === 'country') {
        setSelectedCountry(value);
        localStorage.setItem('sel_country', value);
       
        if(value != '' && discountlist[value]) {
          retrieveProductsforDiscount(value, discountlist[value]);
          localStorage.setItem('country_discount', discountlist[value]);
          
        } else {
          retrieveProducts();
          localStorage.setItem('country_discount', '');
        }
    }
    if(event.target.name === 'courses_classroom') {
      setClassroom(value);
    }
    
  }
  return (
    <>
    {isLoading ? (
        <Loading />
      ) : (
    <div className={`course-selection ${showSuccessMessage ? 'dim-background' : ''}`}>
      {applicationmessage && (
          <div className="set-message-done">
            <span className="cart-message-icon message-icon"><i className="fa fa-check-circle"></i></span> {applicationmessage}
          </div>
        )}
      <img className='two-courses' src={`${process.env.PUBLIC_URL}/images/pay02.png`} alt="smww-pay" />

      <div className="toolbar-pf">
      <p className='please-note'>Please Note:</p>
      <div className="column1">
        <ul>
          <li><span style={{ color: "red" }}>*Prices increase January 1, 2023</span></li>
          <li>Required books are included in course fees.</li>
          <li>Length: 8 Weeks; Includes live weekly audio chats with renowned instructors</li>
        </ul>
      </div>
      <div className="column2">
        <ul>
          <li>Shipping is additional.</li>
          <li>Payment Plans and PayPal financing available.</li>
        </ul>
      </div>
      <div className="column3">
        <ul>
          <li>Discounts available for <a target="_blank" href="https://www.sportsmanagementworldwide.com/military-discount">Military</a>, SMWW Alumni and <a target="_blank" href="https://www.sportsmanagementworldwide.com/economic-discounts">Economically Challenged Countries</a></li>
          <li>Your information will not be sold or shared. For details see our <a target="_blank" href="https://www.sportsmanagementworldwide.com/privacy">Privacy Statement</a>.</li>
        </ul>
      </div>
      <div className="column4">
        <ul className="img-ig">
          <li className="imgssl">
            <img className="ig-1 lazyloaded" style={{ width: 96 }} data-src="https://aisiiczsuo.cloudimg.io/v7/https://www.sportsmanagementworldwide.com/sites/all/themes/smww_commerce/pay/img/rapid.png" src="https://aisiiczsuo.cloudimg.io/v7/https://www.sportsmanagementworldwide.com/sites/all/themes/smww_commerce/pay/img/rapid.png" />
          </li>
          <li className="imgssl" style={{ listStyleType: "none" }}>
            <img className="bblogo ig-2 lazyloaded" width={96} height={36} data-src="https://aisiiczsuo.cloudimg.io/v7/https://www.sportsmanagementworldwide.com/sites/all/themes/smww_commerce/pay/img/accredited-business.png" src="https://aisiiczsuo.cloudimg.io/v7/https://www.sportsmanagementworldwide.com/sites/all/themes/smww_commerce/pay/img/accredited-business.png" />
          </li>
        </ul>
      </div>
      <div className="mobile_secure_image">
        <img className="ig-1 lazyloaded" style={{ width: 96 }} data-src="https://aisiiczsuo.cloudimg.io/v7/https://www.sportsmanagementworldwide.com/sites/all/themes/smww_commerce/pay/img/rapid.png" src="https://aisiiczsuo.cloudimg.io/v7/https://www.sportsmanagementworldwide.com/sites/all/themes/smww_commerce/pay/img/rapid.png" />
        <img className="bblogo ig-2 lazyloaded" style={{ marginLeft: "6%" }} width={96} height={36} data-src="https://aisiiczsuo.cloudimg.io/v7/https://www.sportsmanagementworldwide.com/sites/all/themes/smww_commerce/pay/img/accredited-business.png" src="https://aisiiczsuo.cloudimg.io/v7/https://www.sportsmanagementworldwide.com/sites/all/themes/smww_commerce/pay/img/accredited-business.png" />
      </div>
    </div>
    <form id="course-pay">
    <div className="toolbar-pf-results des">
    <h5>Select Your Course, Pick a Start Date and Unlock Your Potential!</h5>
    <select class="browser-default custom-select" name="country" id="country" value = {country} onChange={handleChange}>
    <option value="">Select Country</option>
            {Object.keys(countrieslist).map(item => {
             
                return <option key={countrieslist[item].sortname} value={countrieslist[item].sortname}>{countrieslist[item].name}</option>
             
              
            })}
    </select>     
    <Link to="/smww/cart">
    <button type="button" className="view-cart-button-smw">View Cart</button>
      </Link>      
    </div>
		 {showSuccessMessage && (
        <div className="success-message">
          <div className="success-message-header"><strong>Added Successfully</strong><span className="close" onClick={() => setShowSuccessMessage(false)}>×</span></div>
          <div className="success-message-content">
          <div className="message-content">
            <div className="message-icon">
              <i className="fa fa-check-circle"></i>
            </div>
            <div className="message-text">
            {message.map((message, index) => (
                 <p>{message}</p>
            ))}
               
            </div>
          </div>
          <div className="success-buttons">
          <Link to="/smww/cart">
            <button className="view-cart-button">View Cart</button>
          </Link> 
          <Link to="/smww/checkout">
            <button className="checkout-button">Checkout</button>
          </Link>   
            <button className="close-button" onClick={() => setShowSuccessMessage(false)}>Close</button>
          </div>
          </div>
        </div>
      )}



      <div className="course-grid">
        {productlist.map((course, index) => (
          <div key={index} className="course-card" id={course.sku}>
            <h3 className='smww-pay-heading'>{course.name}
            {course.org_price &&
            <strike>{course.org_price}</strike>
            }

            </h3>
            {course.paymentOptions.length > 0 && <>
            {course.paymentOptions.map((option, optIndex) => (
              <div key={optIndex} className="payment-option">
                <input
                  type="radio"
                  id={`option-${course.sku}-${optIndex}`}
                  name={`lineitems[${course.sku}}]`}
                  value={optIndex}
                  checked={selectedOptions[course.sku] === optIndex+1}
                  onChange={() => handlePaymentChange(course.sku, optIndex+1)}
                />
                <label className='sta-spay-label' htmlFor={`option-${course.sku}-${optIndex}`}>
                  {option.label} <span className="helptext">{option.help_text}</span><span className="price">{option.price}</span>
                </label>
              </div>
            ))}
          </>}
          {classroomlist[course.sku] &&
            <select name="courses_classroom" className='select-classroom' id={'select'+course.sku} value={classroom[course.sku]} onChange={(e) =>handleClassroomChange(course.sku,e.target.value)}>
             <option value="">Select A Classroom Date: </option>
             <option value="0">I am not ready to pick a start date</option>
             {classroomlist[course.sku]['startdate'].map((option, optIndex) => (
              <option key={option} value={option}>
              {option}
            </option>
             ))}
            </select>
          }
            <button id={`addtocart${course.sku}`} name= {course.sku} className="smww-pay-button" onClick={handleAddtoCart}>Add to Cart</button>
          </div>
        ))}
      </div>
      </form>
    </div>
  )}</>
);
}

export default CoursePay;
