import React from 'react';
import { useState, useEffect, useRef} from 'react';
import { configData } from "../config";
import './classes/OrderDetailsSend.css';
import Loading from './Loading';

const OrderDetailsSend = ({order_id,uid,sku,tuition,payment_type}) => {
 
  const app_url = configData.APP_URL;
  
  const[billing,setBillingDetails] = useState({});
  const[orderProducts,setOrderProducts] = useState([]);
  const[discounts,setDiscounts] = useState([]);
  const[order_details,setOrder] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
   
    retrievePrintOrderDetails();
    setTimeout(() => {
      setIsLoading(false);
    }, 900);
    
  },[]);
  async function retrievePrintOrderDetails() {
    
    const response = await fetch(
      app_url+'smwwreceipt/getuserreceiptdetails?uid='+uid+"&order_id="+order_id+"&payment_type="+payment_type+"&sku="+sku+"&tuition="+tuition,
      {
        method: "GET",
  
        headers: {
          'Content-Type': 'application/json',
        },
      }
    )
    const responsedata = await response.json();
    if(responsedata.data.msg === "Success") {
    
      setBillingDetails(responsedata.data.result['billing']);
      setOrderProducts(responsedata.data.result['product']);
      setDiscounts(responsedata.data.result['discounts']);
      setOrder(responsedata.data.result['order']);
    } 

  }

  
  const domain_url = configData.DOMAIN_URL;
  return (
    <>
        {isLoading ? (
        <Loading />
      ) : (
    <div className="order-details-page-print">
      <div className="order-details-page-print-header">
        <img src={domain_url+"/images/receipt_head_smww_new.png"} alt="Header" />
      </div>
      <div className="order-details-page-print-content">
        <div className='product-order-invoice-details'>
         <div className='invoice-heading'><br/>
        <span><strong>Invoice To:</strong></span>
        <p>{billing.firstname} {billing.lastname},<br />
        {billing.address},<br />
        {billing.city}{", "+billing.state}{", "+billing.country}<br />
        {billing.zipcode},<br />
        {billing.email}</p>
        </div>

         <div className="order-details-right-side">
        <p><strong>Invoice Number:</strong> {order_details.drupal_orderid}</p>
        <p><strong>Date:</strong> {order_details.today_date}</p>
        <p><strong>Amount Paid:</strong> {order_details.order_total}</p>
        <p><strong>Order Id:</strong> {order_details.drupal_orderid}</p>
        </div>
        </div>


<div className='line-for-print-page'>&nbsp;</div><br/>
        <table className="order-details-page-print-table">
          <thead>
            <tr>
              <th width="70%">Product Name</th>
              <th width="15%">Unit Price</th>
              <th width="15%" style={{textAlign: 'right'}}>Price <span className="smallfont">(Discount Applied)</span></th>
            </tr>
          </thead>
          <tbody>
          {orderProducts.map((item, index) => (
             <tr className="order-details-page-even">
             <td>{item.product_title}<br/>{item.classroom_text != '' &&
                      <span>{item.classroom_title+item.classroom_text}</span>
                      }</td>
             <td>${item.unit_amount}</td>
           
             <td style={{textAlign: 'right'}}>${item.calculated_amount}</td>
             
           
           </tr>
             ))}
            
          </tbody>
        </table><br/>
      <div className='print-modal-cty-shp'>
      {discounts && <>
            {discounts.length > 0 && <>
             {
                    discounts.map((item, index) => (
                       <><table className='order-details-disc-shipp'>
            <tbody>
                      <tr>
                       <td className="discount_row"><strong>{item.name}</strong></td>
                        
                        <td className="discount_value">{item.amount}</td>
                      </tr>
                      </tbody>
          </table></>

                     ))
                     
                     }
                     </> 
                    }
             </>
        }
        </div>


        <div className='line-for-print-page'>&nbsp;</div>        

        <div className='print-page-payment-info'>
          <div>
            <span className='print-modal-total-paymt-info'>Payment Information</span>
              <p>{order_details.paid_by}</p>
              <p>{order_details.paid_on}</p>
            </div>
           <div className='orderTotal'>
           <span className="orderTotalText">Total</span> <span className="orderTotalValue" style={{textAlign: 'right'}}>{order_details.order_total}</span>
            </div>    
                
        </div>

        <div className='print-modal-thanks'>
        <div className='thankyou-for-business'><strong>Thank you for your business!</strong></div>

          <p className="president-founder">President & Founder <br />
          <strong>Dr. Lynn Lashbrook</strong></p>
        </div>
        

        <div className="order-details-page-print-footer">
          <img src={domain_url+"/images/receipt_bottom.png"} alt="Footer" />
          
          <div className='print-page-footer-content'>
          <div>
        <p>Sports Management Worldwide <br />
        1100 NW Glisan 2B<br />
        Portland, Oregon, USA 97209</p>
        </div>

        <div>
        <p>US & Canada toll free: 888-95-AGENT<br />
        UK: +44 (0)871 288 4799<br />
        International: 011-503-445-7105<br />
        info@smww.com</p>
        </div>
        </div>
      <p className='order-print-copyright'>Sports Management Worldwide Copyright © 2024 | All Rights Reserved.</p>
        </div>
      </div>
    </div>
    )} </>
  );
};

export default OrderDetailsSend;
