import React, { useState, useEffect,useRef} from 'react';
import './classes/PaymentPage.css';
import { Helmet } from 'react-helmet';
import { configData } from "../config";
import { Link, useNavigate } from 'react-router-dom';
import MinicartLength from './MinicartLength';
import ReactDOM from "react-dom/client";
import ReCAPTCHA from "react-google-recaptcha";
import Loading from './Loading';

const PaymentPage = () => {
  const [paymentMethod, setPaymentMethod] = useState('CreditCard');
  const [sameAsShipping, setSameAsShipping] = useState(true);
  const [formData, setFormData] = useState({
    email: '',
    country: '',
    state: '',
    firstName: '',
    lastName: '',
    address: '',
    addressOptional: '',
    zipCode: '',
    city: '',
    region: '',
    phone: '',
    countryName: '',
    stateName: '',
    stateCode: '',
    refundPolicy: '',
    shippingMethod: '',
    coupon: '',
    cardName: '',
    cardNumber: '',
    cardExpiry: '',
    cardCvc: ''
  });
  const navigate = useNavigate();
  const [countrieslist, setCountriesList] = useState({});
  const [stateslist, setStatesList] = useState({});
  const [cart, setCart] = useState([]);
  const [total, setOrderTotal] = useState();
  const [totalwithshipping, setOrderTotalWithShipping] = useState();
  const [shippingamount, setShippingAmount] = useState();
  const [freeshipping, setFreeShipping] = useState({});
  const [discounts, setDiscounts] = useState({
      discounts:[],
      applied_coupons:[],
  });
  const [conferenceproducts, setConferenceProducts] = useState({});
  const [conferencefnamebadge, setConferenceFirstNameBadge] = useState({});
  const [conferencelnamebadge, setConferenceLastNameBadge] = useState({});
  const [conferenceschoolbadge, setConferenceSchoolBadge] = useState({});
  const [couponerror, setCouponError] = useState();
  const app_url = configData.APP_URL;
  const recaptchaRef = useRef(null);
  const sitekey = configData.GOOGLE_RECAPTCHA_SITE_KEY;
  const [loading, setLoading] = useState(false);
  const [showpayoptions, setpayoptions] = useState(true);
  const [showsezzleoptions, setsezzleoptions] = useState(true);
  const [message, setMessage] = useState({});
  const [loadingthankyoupage, setLoadingThankYouPage] = useState(false);
  const [producttypes, setProductTypes] = useState();
  const [userloggedin, setUserLoggedIn] = useState(false);
  const [orderdetails, setOrderDetails] = useState({});
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
   
    
    apply400discount();
    applyfreeshippingdiscount();
    applyrefereecoupon();
    applyreferraldiscount();
    retrieveCountriesList();
    retrieveLoggedinUserInfo();
    retrieveShippingInfo();
    retrieveConferenceProducts();
    retrieveOrderProductTypes();
    retrieveBillingInfo();
    retrieveCartProducts();
    setTimeout(() => {
      setIsLoading(false);
    }, 1200);
 }, [])
  
 function validateEmail(email) {
  const regexp = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return regexp.test(email);
}
  async function retrieveCountriesList() {
    const countrieslistresponse = await fetch(
      app_url+'smwwcommercecheckout/getcommercecountrieslist',
      {
        method: "GET",

        headers: {
          'Content-Type': 'application/json',
        },
      }
    )
    const countrieslistdata = await countrieslistresponse.json();
  
    const countrieslist = {};
    Object.keys(countrieslistdata.data.result).forEach(function(key) {
    
      countrieslist[key] = countrieslistdata.data.result[key];
  
    });
 
    setCountriesList(countrieslist);
  }
  async function retrieveStatesList(value) {
    const stateslistresponse = await fetch(
      app_url+'smwwcommercecheckout/getcommercestateslist?country='+value,
      {
        method: "GET",

        headers: {
          'Content-Type': 'application/json',
        },
      }
    )
    const stateslistdata = await stateslistresponse.json();
    const stateslist = {};
    Object.keys(stateslistdata.data.result).forEach(function(key) {
    
      stateslist[key] = stateslistdata.data.result[key];
  
    });
 
    setStatesList(stateslist);
  }
  async function retrieveOrderProductTypes() {
    const order_id = localStorage.getItem('order_id');
    const orderProductTypes = await fetch(
      app_url+'smwwcommercecheckout/getorderproducttypes?order_id='+order_id,
      {
        method: "GET",
  
        headers: {
          'Content-Type': 'application/json',
        },
      }
    )
    const orderProductTypesdata = await orderProductTypes.json();
    setProductTypes(orderProductTypesdata.data.result);
    if(orderProductTypesdata.data.result) {
  
      if(!orderProductTypesdata.data.result.includes("1") && !orderProductTypesdata.data.result.includes("9")) {
        setSameAsShipping(false);
      } 
    }
    
  }
  async function retrieveLoggedinUserInfo() {
    const uid = localStorage.getItem('uid');
    if(uid) {
      const response = await fetch(
        app_url+'smwwcommercecheckout/getuserdetails?uid='+uid,
        {
          method: "GET",
    
          headers: {
            'Content-Type': 'application/json',
          },
        }
      )
      const responsedata = await response.json();
      const data = responsedata.data.result;
      if(data) {
      retrieveStatesList(data.country);
      setFormData(prevState => ({
      ...prevState,
      email: data.email,
      country: data.country,
      state: data.state,
      firstName: data.firstname,
      lastName: data.lastname,
      address: data.address,
      addressOptional: data.address1,
      zipCode: data.zipcode,
      city: data.city,
      region: data.region,
      mobile: data.phone,
      }));
    }
    }
  }
  async function retrieveShippingInfo() {
    const order_id = localStorage.getItem('order_id');
    if( order_id) {
      const response = await fetch(
      app_url+'smwwcommercecheckout/getcustomershippingdetails?order_id='+order_id,
      {
        method: "GET",

        headers: {
          'Content-Type': 'application/json',
        },
      }
      )
      const responsedata = await response.json();
      const data = responsedata.data.result;
      const otherdata = responsedata.data;
      if(data) {
        retrieveStatesList(data.country);
        setFormData(prevState => ({
        ...prevState,
        email: data.email,
        country: data.country,
        state: data.state,
        firstName: data.firstname,
        lastName: data.lastname,
        address: data.address,
        addressOptional: data.address1,
        zipCode: data.zipcode,
        city: data.city,
        region: data.region,
        phone: data.phone,
        countryName:otherdata.country_name,
        stateName:otherdata.state_name,
        stateCode:otherdata.state_code,
        shippingMethod:otherdata.shipping_method
        }));
      }
    }  
  }
  async function retrieveBillingInfo() {
    const order_id = localStorage.getItem('order_id');
    if( order_id) {
      const response = await fetch(
      app_url+'smwwcommercepayment/getcustomerbillingdetails?order_id='+order_id,
      {
        method: "GET",

        headers: {
          'Content-Type': 'application/json',
        },
      }
      )
      const responsedata = await response.json();
      const data = responsedata.data.result;
     
      if(data) {
        retrieveStatesList(data.country);
        setFormData(prevState => ({
        ...prevState,
        email: data.email,
        country: data.country,
        state: data.state,
        firstName: data.firstname,
        lastName: data.lastname,
        address: data.address,
        addressOptional: data.address1,
        zipCode: data.zipcode,
        city: data.city,
        region: data.region,
        phone: data.phone
       
        }));
      }
    }  
  }
  async function retrieveConferenceProducts() {
    const order_id = localStorage.getItem('order_id');
    if( order_id) {
      const response = await fetch(
      app_url+'smwwcommercecheckout/getconferenceproducts?order_id='+order_id,
      {
        method: "GET",

        headers: {
          'Content-Type': 'application/json',
        },
      }
      )
      const responsedata = await response.json();
      const data = responsedata.data.products;
      if(data) {
      
      setConferenceProducts(data);

      }
    }
  }
  async function retrieveCartProducts() {
    const order_id = localStorage.getItem('order_id');
    if( order_id) {
      const response = await fetch(
        app_url+'smwwcommercecheckout/getorderdetails?order_id='+order_id,
        {
        method: "GET",

        headers: {
          'Content-Type': 'application/json',
        },
      }
     )
      const responsedata = await response.json();
    
      setCart(responsedata.data.products);
      setOrderTotal(responsedata.data.order_total_without_discount);
      setShippingAmount(responsedata.data.shipping_amount);
      setDiscounts(prevState => ({
        ...prevState,
        discounts: responsedata.data.discounts,
        applied_coupons: responsedata.data.coupon_applied,
      }));
      setFreeShipping(responsedata.data.free_shipping);
      if(responsedata.data.ispaypal === "no") {
        setpayoptions(false);
        
      } else {
        setpayoptions(true);
      }
      if(responsedata.data.issezzle === "no") {
        setsezzleoptions(false);
      } else {
        setsezzleoptions(true);
      }
     
      if(responsedata.data.identity !== null) {
        setUserLoggedIn(true);
      }
      
      setOrderTotalWithShipping(responsedata.data.order_total);
     
    }
  }
  async function apply400discount() {
    const order_id = localStorage.getItem('order_id');
    const response = await fetch(
      app_url+'smwwcommercepayment/smww400discount?order_id='+order_id,
      {
      method: "GET",

      headers: {
        'Content-Type': 'application/json',
      },
    }
   )
   const responsedata = await response.json();
  
  } 
  async function applyrefereecoupon() {
    const order_id = localStorage.getItem('order_id');
    const response = await fetch(
      app_url+'smwwcommercepayment/addrefereecoupon?order_id='+order_id,
      {
      method: "GET",

      headers: {
        'Content-Type': 'application/json',
      },
    }
   )
   const responsedata = await response.json();
  
  } 
  async function applyreferraldiscount() {
    const order_id = localStorage.getItem('order_id');
    if(localStorage.getItem('uid') > 0) {
      const uid = localStorage.getItem('uid');
    const response = await fetch(
      app_url+'smwwcommercepayment/addreferraldiscount?order_id='+order_id+'&uid='+uid,
      {
      method: "GET",

      headers: {
        'Content-Type': 'application/json',
      },
    }
   )
   const responsedata = await response.json();
  }
  
  } 
  async function applyfreeshippingdiscount() {
    const order_id = localStorage.getItem('order_id');
    const response = await fetch(
      app_url+'smwwcommercepayment/smwwaddfreeshippingcoupon?order_id='+order_id,
      {
      method: "GET",

      headers: {
        'Content-Type': 'application/json',
      },
    }
   )
   const responsedata = await response.json();
  
  } 
  const handlePaymentMethodChange = (event) => {
    setPaymentMethod(event.target.value);
  };

  const handleCheckboxChange = (event) => {
    const { name, value } = event.target;
    if(name === 'sameAsShipping') {
      setSameAsShipping(!sameAsShipping);
    }
    if(name === 'refundPolicy') {
        setFormData({ ...formData, [name]: value });
    }
  };

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setFormData({ ...formData, [name]: value });
    if(name === 'country') {
      if(value !== "US") setsezzleoptions(false);
      else setsezzleoptions(true);
      retrieveStatesList(value);
    }
  };
  function validExpiryDate(dValue) {
    var result = true;
    dValue = dValue.split('/');
    var pattern = /^\d{2}$/;
    
  
    if (dValue[0] < 1 || dValue[0] > 12) {
        result = false;
    }
  
    if (pattern.test(dValue[0]) || pattern.test(dValue[1])) {
        result = false;
        
    }
  
    return result;
    
  }
  const validateForm = () => {
    
    const creditCardFields = ['cardName', 'cardNumber', 'cardExpiry', 'cardCvc'];
    
    const errors = [];
    {conferenceproducts.map((item, index) => (
      <>
      
       {!conferencefnamebadge[item.line_item_id] &&
          errors.push({
            name:'firstName',
            line_item_id: item.line_item_id,
            message: "Please enter the conference first name badge for "+item.title,
          })
        }
      {!conferencelnamebadge[item.line_item_id] &&
          errors.push({
            name:'lastName',
            line_item_id: item.line_item_id,
            message: "Please enter the conference last name badge for "+item.title,
          })
        }
     {!conferenceschoolbadge[item.line_item_id] &&
          errors.push({
            name:'school',
            line_item_id: item.line_item_id,
            message: "Please enter the conference school badge for "+item.title,
          })
        }
      </>
    ))}
    var arrayLength = errors.length;
    if(arrayLength > 0) {
    for (var i = 0; i < arrayLength; i++) {
      
      alert(errors[i]['message']);
      return false;
    }
  }
    if (!formData.email) {
      window.alert('Email is required.');
      return false;
    }

    if (!validateEmail(formData.email)) {
      window.alert('Please Enter Valid Email Address.');
      return false;
    }
    if (!formData.country) {
      window.alert('Country is required.');
      return false;
    }
    if (!formData.state) {
      window.alert('State is required.');
      return false;
    }
    if (!formData.firstName.trim()) {
      window.alert('First Name is required.');
      return false;
    }
    if (!formData.lastName.trim()) {
      window.alert('Last Name is required.');
      return false;
    }
    if (!formData.address.trim()) {
      window.alert('Address is required.');
      return false;
    }
    if (!formData.zipCode.trim()) {
      window.alert('Zip Code is required.');
      return false;
    }
    if (!formData.city.trim()) {
      window.alert('City is required.');
      return false;
    }
    if (!formData.phone.trim()) {
      window.alert('Mobile number is required.');
      return false;
    }
    

    if (paymentMethod === 'CreditCard') {
      
      if (!formData.cardName.trim()) {
        window.alert('Card Name is required.');
        return false;
      }
      if (!formData.cardNumber.trim()) {
        window.alert('Card Number is required.');
        return false;
      }
      if (formData.cardNumber.replace(/\s/g, '').length !== 16) {
        alert('Card Number must be 16 digits.');
        return false;
      }
      if (!formData.cardExpiry.trim()) {
        window.alert('Card Expiry is required.');
        return false;
      }
      if(!validExpiryDate(formData.cardExpiry)) {
        window.alert('Please enter a valid date in MM/YYYY format..');
        return false;
      }
      if (!formData.cardCvc.trim()) {
        window.alert('Card CVC is required.');
        return false;
      }
      if (formData.cardCvc.length < 3 || formData.cardCvc.length > 4) {
        alert('Card CVC must be 3 or 4 digits.');
        return false;
      }

     
    }
    const recaptchaValue = recaptchaRef.current.getValue();
    if (!recaptchaValue) {
      alert('Please verify the reCAPTCHA!');
      return false;
      
    }
    if (formData.stateCode === 'OR') {
      if(formData.refundPolicy === '') {
      window.alert('Please check the box to indicate that you have read and agree to the Refund Policy.');
      return false;
      }
    }
    return true;
  };

  const handleSubmit = async event => {
    event.preventDefault();

    if (validateForm()) {
      setLoadingThankYouPage(true); 
      const postData = {
        formData: formData,
        paymentMethod: paymentMethod,
        order_id: localStorage.getItem('order_id'),
        first_name_badge:conferencefnamebadge,
        last_name_badge:conferencelnamebadge,
        school_badge:conferenceschoolbadge,
        }
      const response = await fetch(app_url+'smwwcommercepayment/payment', {
        method: 'POST',
        body: JSON.stringify(postData),
        headers: {
          'Content-Type': 'application/json',
        },
        });
        const data = await response.json();
        if(data.data.result === "Success") {
          setOrderDetails(data.data.order_details['items']);
          console.log(data.data.order_details['items']);
          window.gtag("event", "purchase",  {
            "transaction_id":data.data.order_details['transaction_id'],
            "value":data.data.order_details['order_total'],
            "shipping":data.data.order_details['shipping_amount'],
            "currency": "USD",
            "items": data.data.order_details['items']
          });
          const order_id = localStorage.getItem('order_id');
            
            const root = ReactDOM.createRoot(document.getElementById("top-header-cart-icon"));
            root.render(<MinicartLength length={0} />,document.getElementById("totalItems"));
          if(paymentMethod === "CreditCard") {
            localStorage.removeItem("order_id");
            localStorage.removeItem("drupal_orderid");
            setLoadingThankYouPage(false); 
            navigate("/smww/checkout/thankyou/"+ data.data.order_details['transaction_id']);
          } 
         
          if(paymentMethod === "PayPal") {
            setLoadingThankYouPage(false); 
            navigate("/smww/checkout/paypal");
          } 
          if(paymentMethod === "Sezzle") {
            setLoadingThankYouPage(false); 
            window.location.href = data.data.redirect_url;
            
          } 
         
        }
        else {
          setLoadingThankYouPage(false); 
          setMessage(data.data.error_text);
          window.scrollTo(0, 0);

        }
    }
  };
 
  const handleAddCoupon = async event => { 
    if(!formData.coupon) {
      window.alert('Please enter the coupon.');
      return false;
    }

    setLoading(true); // Start loading
   
    const postData = {
      coupon: formData.coupon,
      order_id: localStorage.getItem('order_id')
      }
    const response = await fetch(app_url+'smwwcommercecheckout/addcoupon', {
      method: 'POST',
      body: JSON.stringify(postData),
      headers: {
        'Content-Type': 'application/json',
      },
      });
      const data = await response.json();
      if(data.data.result.error_msg) {
        setCouponError(data.data.result.error_msg);
      }
      if(data.data.result.success_msg) {
        setCouponError('');
        setFormData(prevState => ({
          ...prevState,
          coupon: ''
        }));
        retrieveCartProducts();
      }
      setLoading(false); // End loading
  };
  const handleRemoveCoupon = async (couponname) => {
    setLoading(true);
    const postData = {
      coupon: couponname,
      order_id: localStorage.getItem('order_id')
      }
      const response = await fetch(app_url+'smwwcommercecheckout/removecoupon', {
        method: 'POST',
        body: JSON.stringify(postData),
        headers: {
          'Content-Type': 'application/json',
        },
        });
        const data = await response.json();
        if(data.data.msg === "Success") {
          apply400discount();
          await retrieveCartProducts();
          
        }
        setLoading(false);
  };
  const handleConferenceFirstNameBadge = (id, sku, value) => {
    
    setConferenceFirstNameBadge(prevState => ({
      ...prevState,
      [id]:{
        sku:sku,
        value:value
      },
    }));
    
    
  };
  const handleConferenceLastNameBadge = (id, sku, value) => {
    
    setConferenceLastNameBadge(prevState => ({
      ...prevState,
      [id]:{
        sku:sku,
        value:value
      },
    }));
    
  };
  const handleConferenceSchoolBadge = (id, sku, value) => {
    
    setConferenceSchoolBadge(prevState => ({
      ...prevState,
      [id]:{
        sku:sku,
        value:value
      },
    }));
    
  };
	const handleCardNumberChange = (event) => {
    const { value } = event.target;
    const formattedValue = value
      .replace(/\s/g, '')
      .replace(/(\d{4})/g, '$1 ')
      .trim();
    if (/^\d{0,16}$/.test(value.replace(/\s/g, ''))) {
      setFormData({ ...formData, cardNumber: formattedValue });
    }
  };

  const handleCardExpiryChange = (event) => {
    const { value } = event.target;
    let formattedValue = value.replace(/\D/g, '');
  
    if (formattedValue.length > 4) {
      formattedValue = formattedValue.replace(/(\d{2})(\d{4})/, '$1 / $2');
    } else if (formattedValue.length > 2) {
      formattedValue = formattedValue.replace(/(\d{2})/, '$1 / ');
    }
  
    setFormData({ ...formData, cardExpiry: formattedValue });
  };
  

  const handleCardCvcChange = (event) => {
    const { value } = event.target;
    if (/^\d{0,4}$/.test(value)) {
      setFormData({ ...formData, cardCvc: value });
    }
  };
  
  return (
    <>
    {isLoading ? (
        <Loading />
      ) : (
    <div className="payment-container">
    {loadingthankyoupage && (
  <div className="loading-overlay">
    <img src={`${process.env.PUBLIC_URL}/images/ajax-loader.gif`} alt="loader" className='thankyou-loader' />
  </div>
)}

      <Helmet><title>SMWW Card Payment</title></Helmet>
      <div className="main-content">
      {message.length > 0 && (
          <div className="set-message-error">
           {message.map((item, index) => {
           return <>{item}<br /></>
          
        })}  
          </div>
        )}
        {producttypes && (
        (producttypes.includes("1") || producttypes.includes("9")) && (
        <div className="payment-info">
          <h2>SHIPPING INFORMATION</h2>
          <div className="payment-shipping-info">
            <div className="shipping-address">
              <p>SHIPPING ADDRESS</p>
            
              <p>{formData.address}<br />
              {formData.stateName}, {formData.zipCode}<br />
              {formData.countryName}</p>
            </div>
            <div className="shipping-method">
              <p>SHIPPING METHOD:</p>
              <p>{formData.shippingMethod}</p>
            </div>
          </div>
        </div>
      ))}
      {conferenceproducts.length > 0 && (
        <div className="pmt-conf-info">
          <p className='conf-badge'>CONFERENCE NAME BADGE</p>
          {conferenceproducts.map((item, index) => (
            <><p><strong>{item.title}</strong></p>
       
            <div className="conf-group">
              <label>Please enter your first name as you would like it to be displayed on your conference name badge</label>
              <input id={"firstName"+item.line_item_id}
              type="text"
              name={'firstName['+item.line_item_id+']'}
              
              onChange={(e) => handleConferenceFirstNameBadge(item.line_item_id, item.sku, e.target.value)}
             required />
            </div>
            <div className="conf-group">
              <label>Please enter your last name as you would like it to be displayed on your conference name badge</label>
              <input id={"lastName"+item.line_item_id} 
              type="text"
              name={'lastName['+item.line_item_id+']'}
              
              onChange={(e) => handleConferenceLastNameBadge(item.line_item_id, item.sku, e.target.value)}
              required />
            </div>
            <div className="conf-group">
              <label>Please enter your school or university as you would like it to be displayed on your conference name badge</label>
              <input id={"school"+item.line_item_id} 
              type="text"
              name={'school['+item.line_item_id+']'}
             
              onChange={(e) => handleConferenceSchoolBadge(item.line_item_id, item.sku, e.target.value)}
              required />
            </div></>
             ))}
        </div>  
      )}
        <div className="payment-section">
          
          <form id="payment-form">
            {!sameAsShipping && (
              <div className="billing-info">
                <h2>BILLING INFORMATION</h2>
                {!localStorage.getItem('uid') &&
                <input type="email" className='payment-page-textbox' name="email" placeholder="Email Address *" value={formData.email} onChange={handleInputChange} required />
                }
                <select name="country" className='payment-page-textbox' value={formData.country} onChange={handleInputChange} required>
                <option value="">Select Country</option>
                {Object.keys(countrieslist).map(item => {
                  return <option key={countrieslist[item].sortname} value={countrieslist[item].sortname}>{countrieslist[item].name}</option>
                })}
              </select>
              <select name="state" className='payment-page-textbox' value={formData.state} onChange={handleInputChange} required>
               <option value="">Select State</option>
                {Object.keys(stateslist).map(state => {
                 return <option key={stateslist[state].id} value={stateslist[state].id}>{stateslist[state].name}</option>
              })}
              </select>
                <div className="ch-inline-inputs">
                <input type="text" className='payment-page-textbox' name="firstName" placeholder="First Name" value={formData.firstName} onChange={handleInputChange} required />
                <input type="text" className='payment-page-textbox' name="lastName" placeholder="Last Name" value={formData.lastName} onChange={handleInputChange} required />
                </div>
                <input type="text" className='payment-page-textbox' name="address" placeholder="Address" value={formData.address} onChange={handleInputChange} required />
                <input type="text" className='payment-page-textbox' name="addressOptional" placeholder="Address (Optional)" value={formData.addressOptional} onChange={handleInputChange} />
                <div className="ch-inline-inputs">
                <input type="text" className='payment-page-textbox' name="zipCode" placeholder="Zip Code" value={formData.zipCode} onChange={handleInputChange} required />
                <input type="text" className='payment-page-textbox' name="city" placeholder="City" value={formData.city} onChange={handleInputChange} required />
                </div>
                <div className="ch-inline-inputs">
                <input type="text" className='payment-page-textbox' name="region" placeholder="Region (Optional)" value={formData.region} onChange={handleInputChange} />
                <input type="text" className='payment-page-textbox' name="phone" placeholder="Phone" value={formData.phone} onChange={handleInputChange} required />
                </div>
              </div>
            )}

            <h2>PAYMENT</h2>
            {producttypes && (
              (producttypes.includes("1") || producttypes.includes("9")) && (
            <div className="billing-address">
              <input type="checkbox" name="sameAsShipping" checked={sameAsShipping} onChange={handleCheckboxChange} />
              <label className='pamt-billing'>My billing address is the same as my shipping address</label>
            </div>
            ))}
            <div className="payment-method">
            {showpayoptions && (
              <div className="payment-method-item">
                <input
                  type="radio"
                  name="paymentMethod"
                  value="PayPal"
                  checked={paymentMethod === 'PayPal'}
                  onChange={handlePaymentMethodChange}
                />
                
                <label className='pmt-type-labels'>
                  PayPal
                  <img src={`${process.env.PUBLIC_URL}/images/paypal_logo.png`} alt="PayPal" className="paypal-logo" />
                </label>
              </div>
            )}
            {showsezzleoptions && (
            <div className="payment-method-item sezzle-cont">
                <input
                  type="radio"
                  name="paymentMethod"
                  value="Sezzle"
                  checked={paymentMethod === 'Sezzle'}
                  onChange={handlePaymentMethodChange}
                />
                <label className='pmt-type-labels'>
                Sezzle
                  <img src={`${process.env.PUBLIC_URL}/images/sezzle_logo.png`} alt="Sezzle" className="sezzle-logo" />
                </label>
                <span className='bnpl'>Buy Now, Pay Later</span>
              </div>    
              )}
            
              <div className="payment-method-item">
                <input
                  type="radio"
                  name="paymentMethod"
                  value="CreditCard"
                  checked={paymentMethod === 'CreditCard'}
                  onChange={handlePaymentMethodChange}
                />
                <label className='pmt-type-labels'>
                  Credit or Debit Card
                  <img src={`${process.env.PUBLIC_URL}/images/card_img.png`} alt="Credit Cards" className="credit-card-logos" />
                </label>
              </div>
            </div>

           {paymentMethod === 'CreditCard' && (
              <>
                <input type="text" className='payment-page-textbox' name="cardName" placeholder="Name On The Card" value={formData.cardName} onChange={handleInputChange} required />
                <div className='credit-card-input'>
                  <input
                    type="tel"
                    className='payment-page-textbox'
                    name="cardNumber"
                    placeholder="Card Number"
                    value={formData.cardNumber}
                    onChange={handleCardNumberChange}
                    pattern="\d{4}\s\d{4}\s\d{4}\s\d{4}"
                    required
                  />
                  <span className='cc-card'><i className="fa-regular fa-credit-card"></i></span>
                </div>
                <div className="card-expiry-cvc">
                  <input
                    type="tel"
                    className='payment-page-textbox'
                    name="cardExpiry"
                    placeholder="MM / YYYY"
                    value={formData.cardExpiry}
                    onChange={handleCardExpiryChange}
                    pattern="\d{2}\s/\s\d{4}"
                    required
                  />
                  <input
                    type="tel"
                    className='payment-page-textbox'
                    name="cardCvc"
                    placeholder="CVC"
                    value={formData.cardCvc}
                    onChange={handleCardCvcChange}
                    pattern="\d{3,4}"
                    required
                  />

                </div>
              </>
            )}
            {formData.stateCode === 'OR' ? (
              <div className="refund-policy">
                <h2>Refund Policy</h2>
                <p>Click here to read the <a href="https://www.sportsmanagementworldwide.com/node/703" target="_blank">
											 <strong>Oregon Department of Education Correspondence Student Cancellation and Refund Policies: OAR 581-045-0037</strong></a> </p>
                       {producttypes && (
                        producttypes.includes("9") && (
                        <p>*There are no refunds or returns for apparel items unless there is a manufacturing defect, in which case you can exchange it for the same item.</p>
                      ))}
                       <input type="checkbox" id="or_refund_policy" name="refundPolicy" value="Yes" required onChange={handleCheckboxChange}/> I&#39;ve read and agree to the Refund Policy
                       
              </div>
            ):(
              <>
              {producttypes && (
                 producttypes.includes("9") && (
              <div className="refund-policy">
                <h2>Refund Policy</h2>
                <p>*There are no refunds or returns for apparel items unless there is a manufacturing defect, in which case you can exchange it for the same item.</p>
              </div>
              )
            )}</>
            )
            }
            <div className="recaptcha">
            <ReCAPTCHA
              ref={recaptchaRef}
              sitekey={sitekey}
              size="visible"
              required
            />
            </div>
            
            <button type="submit" className="pay-now-button" onClick={handleSubmit}>
            {paymentMethod === 'PayPal' ? 'Proceed to PayPal' : paymentMethod === 'Sezzle' ? 'Proceed to Sezzle' : 'Pay Now'}
            </button>
          </form>
          
        </div> 
      </div>

      <div className="checkout-page-order-summary left-space">
        <h2>YOUR ORDER</h2>
        <div className="ch-order-details">
          <div>
            <div className="order-items">
              <span>Product Items ({cart.length})</span>
              <span>${total}</span>
            </div>
            <hr className='payment-hr' />
            {discounts.discounts.length > 0 &&
              <div>
              {discounts.discounts.map((item, index) => (
                 <>
                {item.amount > 0 &&
                 <><div className="order-shipping"><span>{item.name}</span><span>${item.amount.toLocaleString(undefined, {minimumFractionDigits: 2})}</span></div><hr className='payment-hr' /></>
                }
                 </>
              ))}
             </div>
             }
          
            
            {shippingamount > 0 && 
              <><div className="order-shipping"><span>Shipping</span>
              <span>${shippingamount}</span></div>
              <hr className='payment-hr' /></>
            }
            
            
            {freeshipping.amount > 0 && 
            <>
              <div className="order-shipping">
                <span>Shipping</span>
                <span>${freeshipping.amount.toLocaleString(undefined, {minimumFractionDigits: 2})}</span>
              </div>
              <hr className='payment-hr' />
              <div className="order-shipping"><span>Freeshipping({freeshipping.line_item_label})</span><span>-${freeshipping.amount.toLocaleString(undefined, {minimumFractionDigits: 2})}</span></div>
              <hr className='payment-hr' /></>
            }
            <div className="ch-order-total">
              <span className='ch-total'>TOTAL</span>
              <span className='ch-total-amount'>${totalwithshipping >0 ? totalwithshipping.toLocaleString(undefined, {minimumFractionDigits: 2}) : 0.00}</span>
            </div>
            <button type="button" className="pay-now-oder-button" onClick={handleSubmit}> {paymentMethod === 'PayPal' ? 'Proceed to PayPal' : paymentMethod === 'Sezzle' ? 'Proceed to Sezzle' : 'Pay Now'}
            </button>
          </div>
          <div className='coupon-container-dotted'>
            <div className="coupon-container">
              <input type="text" name="coupon" placeholder="Coupon" value={formData.coupon} onChange={handleInputChange}/>
              <button className="ch-apply-coupon apply-coupon" onClick={handleAddCoupon}>Apply</button>
            </div>

            {loading && (
                <div>
                  <img src={`${process.env.PUBLIC_URL}/images/ajax-loader.gif`} alt="loader" className='loader' />
                </div>
              )}
            {couponerror &&
                  <span className="error-message">{couponerror}</span>
                }
          </div>
          </div>
            {discounts.applied_coupons.length > 0 &&
              <div>
                <h2>Applied Coupon</h2>
                <div className="coupon-details">
                {discounts.applied_coupons.map((item, index) => (
                    <><p>{item.name}</p><p>${item.unit_amount}</p>
                    <p><a className="coupon-remove" onClick={() => handleRemoveCoupon(item.name)}>Remove</a></p><br /></>
                ))}
                </div>
              </div>
            }
            
            <div>
            <div>
            <h3 className='prod-pay'>PRODUCT DETAILS</h3>
            {cart.map((item, index) => (
                <div className="product-details" key={index}>
                  <div className="details-row">
                    <span>{item.name}{item.classroom_name && <> ({item.classroom_name})</>
                    }{item.quantity && <> ({'Qty: '+item.quantity})</>
                  }</span>
                    <span>${item.calculated_amount}</span>
                  </div>
                  {(item.classroom && item.classroom != 0) &&
                  <span>Classroom Start Date: {item.classroom}</span>
                  }
                   {item.classroom == 0 &&
                  <span>Classroom Start Date: I am not ready to pick a start date</span>
                  }
                  <hr className='checkout-hr' />
                </div>
                
              ))}
          </div>

          <div className="pay-order-note">
            <span>*Discounts are applied during checkout.</span>
            <hr className='payment-hr' />
            <span>**All prices are in USD.</span>
            <hr className='payment-hr' />
            <p className='pay-last'>***Entering your contact information during checkout will put you on SMWW's newsletter and SMS 
              list which you can unsubscribe from at any time. <a href='https://www.sportsmanagementworldwide.com/privacy'>Click</a> here to view our Privacy Policy.</p>
          </div>
        </div>
      </div>
    </div>
    )}</>
  );
};

export default PaymentPage;
